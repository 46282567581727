import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../layout/layout"
import HighlightSeries from "../../components/common/highlightsSeries"
import Seo from "../../components/seo/seo"
import { useTranslation } from "react-i18next"
import Img from 'gatsby-image'
import Link from "../../utilities/link"
import Modal from "../../components/common/modal"
import LaundryNews from "../../components/forms/laundryNewsForm"
import ExitIntent from "../../utilities/exitIntent"

const HeraBasicOPL = ({ data }) => {
  const { t } = useTranslation();
  // gestione popup modal

  const [showModal, setShowModal] = useState(false);

  // caricamento dopo 10 secondi

  useEffect(() => {
    // dopo 10 secondi dal caricamento della pagina, compare il popup
    const timer = setTimeout(() => setShowModal(true), 10000);
    //poi il timer si resetta
    return () => clearTimeout(timer);
  }, []);

  // caricamento on exit (con cookie per evitare che si ripresenti il popup fino al giorno successivo)

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 30,
      eventThrottle: 100,
      onExitIntent: () => {
        if (document.cookie.indexOf("modal_seen=true") < 0) {
          setShowModal(true)
          let expiryDate = new Date(
            Date.now() + 1 * (1000 * 60 * 60 * 24)
          )
          expiryDate.setFullYear(expiryDate.getFullYear() + 1)
          document.cookie =
            "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
          }
      },
    })
    return () => {
      removeExitIntent()
    }
  })

  return (
  <Layout>
      { showModal 
      ? 
      <Modal
        isActive = "is-active"
        component={<LaundryNews />}
        onCloseClick={() => setShowModal(false)}
      /> 
      : 
      null
    }
    <Seo
      title={t("herabasic.seoTitle")}
      description={t("herabasic.seoDescription")}
      seoImage={data.hera_basic.childImageSharp.resize.src}
    />
    {/* Sezione Header */}
    <div className="section is-medium is-relative has-light-background">
    <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
      <defs>
      <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
      <stop stop-color="#7dd1a7" offset="0"/>
      <stop stop-color="#71bdf4" offset="1"/>
      </linearGradient>
      </defs>
      <g id="Livello 1" layerName="Livello 1">
      <path d="M-1.43513+0.575764L2002.67+0.575764L2000+381.632C2000+381.632+1827.32+319.668+1447.66+379.973C1068.01+440.279+830.684+620.543+474.162+617.13C109.78+613.64-2.95224+456.261-2.95224+456.261L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
      </g>
    </svg>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            <div className="content is-large">
              <h1 className="is-white is-bottom-bordered-white">{t('herabasic.pageTitle')}</h1>
              <h2 className="title is-4" style={{marginTop: 0}}>{t('herabasic.pageSubtitle')}</h2>
            </div>
          </div>
          <div className="column is-6 has-text-centered">
            <Img fluid={data.hera_basic.childImageSharp.fluid} alt={t('herabasic.altImageHeraBasic')} />
          </div>
        </div>
      </div>
    </div>
    {/* Sezione Mission */}
      <HighlightSeries
        //illustration={<SvgHeraLaundry />}
        //fluid={data.textile_laundry.childImageSharp.fluid}
        mainTitle={t('herabasic.missionTitle')}
        titleColour=""
        isGradient="has-light-background"
        title={t('herabasic.missionSubtitle')}
      />
    {/* Sezione Overview */}
    <div className="section is-medium">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-5">
            <div className="content is-large is-bottom-bordered">
              <h2 className="title is-1">
                {t('herabasic.overviewTitle')}</h2>
            </div>
            <div className="content">
              <p className="is-subtitle">
              {t('herabasic.overviewSubtitle')}
              </p>
            </div>
            <Img fluid={data.kit_hera_basic.childImageSharp.fluid} alt={t('herabasic.altImageHeraBasicKit')}/>
          </div>
          <div className="column is-1"></div>
          <div className="column is-6">
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px', borderBottom: '3px solid #7dd1a7'}}>
              <h3 className="title is-4">{t('herabasic.overviewP1Title')}</h3>
              <ul>
                  <li>{t('herabasic.overviewP1Li1')}</li>
                  <li>{t('herabasic.overviewP1Li2')}</li>
                  <li>{t('herabasic.overviewP1Li3')}</li>
                  <li>{t('herabasic.overviewP1Li4')}</li>
                </ul>
            </div>
            <div className="content" style={{paddingTop: '50px', paddingBottom: '75px', borderBottom: '3px solid #7dd1a7'}}>
              <h3 className="title is-4">{t('herabasic.overviewP2Title')}</h3>
              <ul>
                  <li>{t('herabasic.overviewP2Li1')}</li>
                  <li>{t('herabasic.overviewP2Li2')}</li>
                  <li>{t('herabasic.overviewP2Li3')}</li>
                  <li>{t('herabasic.overviewP2Li4')}</li>
                  <li>{t('herabasic.overviewP2Li5')}</li>
                  <li>{t('herabasic.overviewP2Li6')}</li>
                  <li>{t('herabasic.overviewP2Li7')}</li>
                  <li>{t('herabasic.overviewP2Li8')}</li>
                </ul>
            </div>
            <div className="content" style={{paddingTop: '50px'}}>
              <h3 className="title is-4">{t('herabasic.overviewP3Title')}</h3>
              <ul>
                  <li>{t('herabasic.overviewP3Li1')}</li>
                  <li>{t('herabasic.overviewP3Li2')}</li>
                  <li>{t('herabasic.overviewP3Li3')}</li>
                  <li>{t('herabasic.overviewP3Li4')}</li>
                  <li>{t('herabasic.overviewP3Li5')}</li>
                  <li>{t('herabasic.overviewP3Li6')}</li>
                </ul>
            </div>
          </div>
          <div className="column is-6 has-text-right" style={{paddingTop: '75px'}}>
            <Link to="/contatti" className="button is-medium is-outlined is-info"><strong>{t('herabasic.overviewButton1')}</strong></Link>
          </div>
          <div className="column is-6 has-text-left" style={{paddingTop: '75px'}}>
            <Link to="/contatti" className="button is-medium is-outlined is-primary"><strong>{t('herabasic.overviewButton2')}</strong></Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}
export default HeraBasicOPL

export const Home = graphql`
  query {
    hera_basic: file(
      relativePath: { eq: "Hera_Basic_Electronic_Control_Washing_Machines.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    kit_hera_basic: file(
      relativePath: { eq: "Electronic_for_laundry_equipment_Hera_Basic.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
